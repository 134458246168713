import * as _reactDom2 from "react-dom";
var _reactDom = _reactDom2;
try {
  if ("default" in _reactDom2) _reactDom = _reactDom2.default;
} catch (e) {}
var exports = {};
var m = _reactDom;
if (true) {
  exports.createRoot = m.createRoot;
  exports.hydrateRoot = m.hydrateRoot;
}
export default exports;
export const createRoot = exports.createRoot,
  hydrateRoot = exports.hydrateRoot;